import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { useHistory } from "react-router-dom";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import usePerformanceAnalyticsHistoric from "./usePerformanceAnalyticsHistoric";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import AverageGraphic from "./averageGraphic";
import DetailGrid from "./detailGrid";

const YEARS_ARRAY = [
  { value: "Todos los históricos", key: "" },
  { value: "Últimos 3 años", key: "3" },
  { value: "Últimos 5 años", key: "5" },
  { value: "Últimos 10 años", key: "10" },
];

const PerformanceAnalyticsHistoric = () => {

  const {
    isLoading,
    averagePerformanceData,
    averagePotentialData,
    detailData,
    state,
    handlers,
  } = usePerformanceAnalyticsHistoric();

  const history = useHistory();

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString('goBack')}
      onClick={() => history.push(paths.performanceAnalytics)}
      type={'goBack'}
    />
  )

  const customHeader = () =>
    <div style={{ width: 300, marginLeft: 10, marginRight: 10 }}>
      <FormControl style={{ width: "100%" }}>
        <InputLabel shrink id={`label-years`}>{"Años a consultar"}</InputLabel>
        <Select
          value={state.years}
          labelId={`label-years`}
          id={`select-years`}
          onChange={(e) => handlers.handleChangeYears(e.target.value)}
          margin={"none"}
          displayEmpty
        >
          {
            YEARS_ARRAY.map(item => (
              <MenuItem
                className={"whiteText"}
                key={item.key}
                value={item.key}
              >
                {item.value}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </div>

  return (
    <CommonPage
      isLoading={isLoading}
      title={"Análisis histórico del desempeño"}
      customHeader={customHeader}
      menuItems={[goBackButton]}
      isNotGridPage
      hasSwitch
      handleSwitchClick={handlers.handleChangeIncludeCurrentYearProcesses}
      switchValue={state.includeCurrentYearProcesses}
      switchLabelOn={"Incluir procesos del año actual"}
      switchLabelOff={"Incluir procesos del año actual"}
    >
      <div style={{ margin: "0px 24px" }}>
        {
          Boolean(averagePerformanceData.length) &&
          <AverageGraphic
            title={"Promedio global del desempeño por evaluación"}
            data={averagePerformanceData}
          />
        }
        {
          Boolean(averagePotentialData.length) &&
          <AverageGraphic
            title={"Promedio global del potencial por evaluación"}
            data={averagePotentialData}
          />
        }
        <DetailGrid
          title={"Detalle de las evaluaciones del desempeño para el análisis histórico"}
          data={detailData}
        />
      </div>
    </CommonPage>
  );
}

export default PerformanceAnalyticsHistoric;

import * as actionTypes from "./actionTypes";
import {
  getPerformanceAnalyticsTalentAPI,
  getPerformanceAnalyticsTalentQuadrantAPI,
  getPerformanceAnalyticsTalentSummaryAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const getPerformanceAnalyticsTalentAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_EVALUATIONS });
  try {
    let response = await getPerformanceAnalyticsTalentAPI();

    let evaluations = response.data && response.data.data;
    let showRecognitionPoints = response.data && response.data.showRecognitionPoints;
    let showSalaryInfo = response.data && response.data.showSalaryInfo;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_EVALUATIONS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_EVALUATIONS_FULFILLED,
      payload: {
        evaluations,
        showRecognitionPoints,
        showSalaryInfo,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_EVALUATIONS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e;
  }
};

export const getPerformanceAnalyticsTalentQuadrantAction = (evaluationCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_QUADRANT_DATA });
  try {
    let response = await getPerformanceAnalyticsTalentQuadrantAPI(`?evaluationCode=${evaluationCode}`);

    let quadrantData = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_QUADRANT_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_QUADRANT_DATA_FULFILLED,
      payload: {
        quadrantData,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_QUADRANT_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e;
  }
};

export const getPerformanceAnalyticsTalentSummaryAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_SUMMARY_DATA });
  try {
    let response = await getPerformanceAnalyticsTalentSummaryAPI(dataToSend);

    let summaryData = response.data && response.data.data;
    let npsName = response.data && response.data.npsName;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_SUMMARY_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_SUMMARY_DATA_FULFILLED,
      payload: {
        summaryData,
        npsName,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_SUMMARY_DATA_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e;
  }
};
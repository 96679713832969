import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getPerformanceAnalyticsHistoricAction,
} from "../actions";
import {
    getIsLoading,
    getAveragePerformanceData,
    getAveragePotentialData,
    getDetailData,
} from "../selectors";

const usePerformanceAnalyticsHistoric = () => {

    const [years, setYears] = useState("");
    const [includeCurrentYearProcesses, setIncludeCurrentYearProcesses] = useState(true);

    const dispatch = useDispatch();
    const isLoading = useSelector(getIsLoading);
    const averagePerformanceData = useSelector(getAveragePerformanceData);
    const averagePotentialData = useSelector(getAveragePotentialData);
    const detailData = useSelector(getDetailData);

    useEffect(() => {
        dispatch(getPerformanceAnalyticsHistoricAction(includeCurrentYearProcesses, years));
    }, [dispatch, includeCurrentYearProcesses, years])

    const handleChangeYears = (newValue) => {
        setYears(newValue);
    }

    const handleChangeIncludeCurrentYearProcesses = () => {
        setIncludeCurrentYearProcesses(prev => !prev);
    }

    const state = {
        years,
        includeCurrentYearProcesses,
    }

    const handlers = {
        handleChangeYears,
        handleChangeIncludeCurrentYearProcesses,
    }

    return {
        isLoading,
        averagePerformanceData,
        averagePotentialData,
        detailData,
        state,
        handlers,
    }
}

export default usePerformanceAnalyticsHistoric;

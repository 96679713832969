import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const CharacteristicsDialog = ({ open, data, handleClose }) => {

  const orderedData = [...data]?.sort((a, b) => (a.key > b.key) ? 1 : -1);

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {"Características de los cuadrantes"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        {
          orderedData.map((item) => {
            return (
              <div style={{ paddingBottom: 25 }} key={item.key}>
                <Typography className="whiteText" style={{ fontSize: 20, fontWeight: 600}}>
                  {`Cuadrante ${item.key}: ${item.name}`}
                </Typography>
                <Typography className="whiteText" style={{ whiteSpace: 'pre-line', paddingTop: 10 }}>
                  {item.characteristics || 'No se definieron características para este cuadrante.'}
                </Typography>
              </div>
            )
          })
        }
      </DialogContent>
    </Dialog>
  )
}

export default CharacteristicsDialog;
import React, { useEffect, useMemo } from "react";
import paths from "@icarius-localization/paths";
import { Grid } from "@material-ui/core";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import {
    numberComparatorMax2,
    numberFormatterMin0Max0,
    numberFilterParams,
} from "@icarius-table/number";
import NineBoxImageRenderer from "@icarius-table/renderersAndFilters/nineBoxImageRenderer";
import NPSRenderer from "@icarius-table/renderersAndFilters/npsRenderer";
import CommonPage from "@icarius-common/commonPage";
import WordsData from './wordsData';
import { FILTERS_TITLES } from "../filtersSelection";

const Summary = (props) => {

    const {
        data,
        npsName,
        filters,
        showRecognitionPoints,
        showSalaryInfo,
    } = props;

    useEffect(() => {
        const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
        const element = document.getElementById('summary');
        const posi = element.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({ top: posi, behavior: 'smooth' });
    }, [])

    const ownColumnDef = useMemo(() => {
        let columnDefinitions = getColumnDefByPage(paths.performanceAnalyticsEvaluations)
            .filter((item) => {
                if (item.field === 'recognitionPoints' && !showRecognitionPoints) return false;
                if (['salaryUnit', 'coin', 'salary'].includes(item.field) && !showSalaryInfo) return false;
                return true;
            });

        let npsField = columnDefinitions.find((item) => item.field === 'nps');
        npsField.headerName = `NPS - ${npsName}` || 'NPS';

        const dynamicColumns = Object.keys(filters)
            .map((item, index) => {
                if (!filters[item]) return null;

                let newColumn = {
                    headerName: FILTERS_TITLES[index],
                    field: item,
                    filter: "agSetColumnFilter",
                }

                if (item === 'manager') {
                    newColumn.filter = "agTextColumnFilter";
                }

                if (item === 'seniority') {
                    newColumn = {
                        ...newColumn,
                        filter: "agTextColumnFilter",
                        comparator: numberComparatorMax2,
                        filterParams: numberFilterParams,
                        chartDataType: "series",
                        valueFormatter: numberFormatterMin0Max0,
                        enableValue: true,
                        allowedAggFuncs: ["sum", "min", "max", "avg"],
                    }
                }

                return newColumn;
            })
            .filter((item) => item);

        return [...columnDefinitions, ...dynamicColumns];
    }, [showRecognitionPoints, showSalaryInfo, filters, npsName])

    return (
        <Grid item xs={12} id="summary">
            <CommonPage
                gridTitle={'Resumen de analítica'}
                ownColumnDef={ownColumnDef}
                rowData={data}
                gridHeight={'500px'}
                frameworkComponents={{ NineBoxImageRenderer, NPSRenderer }}
                ignoreProcessesbyPath
                hasSelectAll
                hasHelp
                hasExpand
            />
            <WordsData
                data={data}
                filters={filters}
            />
        </Grid>
    );
}

export default Summary;

import React from "react";
import { Grid } from "@material-ui/core";
import Container from "./container";
import BarChart from "./barChart";

const AverageGraphic = ({ title, data }) => {

  const valueArray = data.map((item) => item.value);
  const nameArray = data.map((item) => item.name);

  return (
    <Container title={title}>
      <Grid container item xs={12}>
        <BarChart data={[{ data: valueArray }]} list={nameArray} />
      </Grid>
    </Container>
  )
}

export default AverageGraphic;
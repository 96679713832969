import React, { useState, useRef } from "react";
import { Button, Typography } from "@material-ui/core";
import ReactApexChart from 'react-apexcharts';
import { shadeHexColor } from "@icarius-utils/colors";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import { getTheme } from "@icarius-pages/login/selectors";
import { getChartWidth } from "@icarius-pages/pollReview/components/pollSelection";
import useGetRefWidthAndHeight from "@icarius-utils/hooks/useGetRefWidthAndHeight";

const EvaluationSelection = (props) => {

  const {
    data,
    years,
    evaluationSelected,
    handleClick,
    handleYearClick,
  } = props;

  const ref = useRef();
  const { width: containerWidth } = useGetRefWidthAndHeight(ref);
  const [selectedYear, setSelectedYear] = useState(years?.at(-1));

  const color = useSelector(getAppColor);
  const { theme } = useSelector(getTheme);

  const evaluationsToUse = data[years?.indexOf(selectedYear)].evaluations;

  const getColorArray = () => {
    const colors = Array(evaluationsToUse.length).fill(color);
    colors[evaluationsToUse.findIndex((evaluation) => evaluation.code === evaluationSelected?.code)] = shadeHexColor(color, 0.3);

    return colors;
  }

  const chartData = {
    series: [
      {
        data: evaluationsToUse.map(item => item.answersAmount),
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 250,
        width: getChartWidth(evaluationsToUse.length, containerWidth - 20), // el - 20 es por el padding del div
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection(event, chartContext, config) {
            handleClick(evaluationsToUse[config.dataPointIndex]);
          }
        },
      },
      colors: getColorArray(),
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      xaxis: {
        categories: evaluationsToUse.map(item => item.name),
        labels: {
          trim: true,
          style: {
            colors: 'var(--mainText)',
          },
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: 'var(--mainText)',
          },
          formatter: (value) => formatNumberExactDecimals(value, 0),
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      states: {
        active: {
          filter: {
            type: 'none',
          }
        }
      },
      tooltip: {
        marker: {
          show: false,
        },
        followCursor: true,
        theme: theme,
        y: {
          formatter: (value) => formatNumberExactDecimals(value, 0),
          title: {
            formatter: function () {
              return ''
            }
          }
        },
      }
    }
  };

  return (
    <>
      <div className="container relative p-16 sm:p-24 flex flex-row justify-between items-center">
        <div className="flex-col">
          <Typography className="whiteText">
            Evaluaciones
          </Typography>
        </div>
        <div className="flex flex-row items-center">
          {
            years?.map((year) => {
              const isSelected = year === selectedYear;
              return (
                <Button
                  key={year}
                  onClick={() => {
                    setSelectedYear(year);
                    handleYearClick();
                  }}
                  disabled={isSelected}
                  style={{
                    marginRight: 5,
                    backgroundColor: isSelected ? "rgba(0, 0, 0, 0.1)" : "",
                  }}
                >
                  {year}
                </Button>
              )
            })
          }
        </div>
      </div>
      <div ref={ref} style={{ padding: 10, overflow: 'auto' }}>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type={chartData.options.chart.type}
          height={chartData.options.chart.height}
          width={chartData.options.chart.width}
        />
      </div>
    </>
  );
}

export default EvaluationSelection;

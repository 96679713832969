import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Typography,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import EvaluationSelection from "./evaluationSelection";
import ScoreSelection from "./scoreSelection";
import Summary from "./summary";
import usePerformanceAnalyticsEvaluations from "./usePerformanceAnalyticsEvaluations";
import FiltersSelection from "./filtersSelection";
import SelectedEvaluationData from "./selectedEvaluationData";
import SelectedScoreData from "./selectedScoreData";

const PerformanceAnalyticsEvaluations = () => {

  const {
    isLoading,
    years,
    evaluations,
    scoreData,
    summaryData,
    npsName,
    showRecognitionPoints,
    showSalaryInfo,
    societySelected,
    state,
    handlers,
  } = usePerformanceAnalyticsEvaluations();

  const history = useHistory();

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString('goBack')}
      onClick={() => history.push(paths.performanceAnalytics)}
      type={'goBack'}
    />
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Analítica de evaluaciones del desempeño'}
      menuItems={[goBackButton]}
      isNotGridPage
    >
      {
        !isLoading && !Boolean(evaluations?.length) &&
        <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
          <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
            {"Aún no se han registrado evaluaciones."}
          </Typography>
        </Grid>
      }
      {
        Boolean(evaluations.length) &&
        <>
          <Grid container item xs={12} className="p-0 principal chart-wrapper" id="main-content" style={{ overflow: 'initial' }}>
            <EvaluationSelection
              years={years}
              data={evaluations}
              evaluationSelected={state.evaluationSelected}
              handleClick={handlers.handleSelectEvaluation}
              handleYearClick={handlers.handleClearEvaluationSelected}
            />
          </Grid>
          {
            Boolean(state.evaluationSelected) && Boolean(Object.keys(scoreData).length) &&
            <>
              <SelectedEvaluationData
                data={state.evaluationSelected}
              />
              <Grid container item xs={12} className="p-0 principal chart-wrapper" id="main-content" style={{ overflow: 'initial' }}>
                <ScoreSelection
                  data={scoreData}
                  scoreSelected={state.scoreSelected}
                  handleClick={handlers.handleSelectScore}
                />
              </Grid>
              {
                Boolean(state.scoreSelected) &&
                <>
                  <SelectedScoreData
                    data={state.scoreSelected}
                  />
                  <Grid container item xs={12} className="p-0 principal chart-wrapper" id="main-content">
                    <FiltersSelection
                      data={state.filtersSelected}
                      handleClick={handlers.handleChangeFilter}
                      handleSubmit={handlers.handleGetSummary}
                    />
                  </Grid>
                </>
              }
            </>
          }
          {
            Boolean(summaryData.length) &&
            Boolean(state.scoreSelected) &&
            Boolean(state.evaluationSelected) &&
            Object.values(state.filtersSelected).some((item) => item) &&
            <Summary
              data={summaryData}
              npsName={npsName}
              filters={state.filtersSelected}
              showRecognitionPoints={showRecognitionPoints}
              showSalaryInfo={showSalaryInfo}
              societySelected={societySelected}
            />
          }
        </>
      }
    </CommonPage>
  );
}

export default PerformanceAnalyticsEvaluations;

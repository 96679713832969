import paths from "@icarius-localization/paths";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Código del evaluado",
            field: "evaluatedCode",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Nombre del evaluado",
            field: "evaluatedName",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Nota final desempeño",
            field: "performanceFinalScore",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Puntaje final desempeño",
            field: "performanceFinalValue",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Nota final potencial",
            field: "potentialFinalScore",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Puntaje final potencial",
            field: "potentialFinalValue",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config: {
            headerName: "Clasificación 9-Boxes",
            field: "nineBoxClassification",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config: {
            headerName: "Calibrado desde 9-Boxes",
            field: "nineBoxCalibration",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Clasificación cuadrante",
            field: "quadrantClassification",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: 'NineBoxImageRenderer'
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Feedbacks recibidos en el período",
            field: "feedbacksReceived",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Feedbacks solicitados en el período",
            field: "feedbacksRequested",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Puntos recibidos de reconocimiento",
            field: "recognitionPoints",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Méritos recibidos",
            field: "merits",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Deméritos recibidos",
            field: "demerits",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "NPS",
            field: "nps",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: 'NPSRenderer'
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Unidad salarial",
            field: "salaryUnit",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Moneda",
            field: "coin",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsEvaluations],
        config:
        {
            headerName: "Sueldo/Salario",
            field: "salary",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]
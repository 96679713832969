import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";

const SelectedQuadrantData = ({ data, collaborators }) => {

  const collaboratorsAmount = collaborators.filter((item) => item.quadrantClassification === data.key).length;

  useEffect(() => {
    const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
    const element = document.getElementById('selectedQuadrantData');
    const posi = element.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: posi, behavior: 'smooth' });
  }, [data.key])

  return (
    <div style={{ margin: 20 }} id="selectedQuadrantData">
      <Typography className="whiteText" align="center" style={{ fontSize: 28 }}>
        {`Grupo de evaluados seleccionados del cuadrante ${data.key}: ${data.name}`}
      </Typography>
      <Typography className="whiteText" align="center" style={{ fontSize: 20 }}>
        {`Cantidad de colaboradores en este cuadrante: ${collaboratorsAmount}`}
      </Typography>
    </div>
  );
}

export default SelectedQuadrantData;
import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import {
  PerformanceAnalyticsEvaluationsIcon,
  PerformanceAnalyticsTalentIcon,
  PerformanceAnalyticsHistoricIcon,
} from "@icarius-icons/";

const PerformanceAnalyticsScreenSelector = () => {

  const data = [
    {
      name: "Analítica de evaluaciones del desempeño",
      icon: <PerformanceAnalyticsEvaluationsIcon />,
      path: paths.performanceAnalyticsEvaluations,
    },
    {
      name: "Analítica del talento",
      icon: <PerformanceAnalyticsTalentIcon />,
      path: paths.performanceAnalyticsTalent,
    },
    {
      name: "Análisis histórico del desempeño",
      icon: <PerformanceAnalyticsHistoricIcon />,
      path: paths.performanceAnalyticsHistoric,
    },
  ];

  return (
    <ScreenSelector
      title={'Analítica del desempeño y talento'}
      data={data}
    />
  );
}

export default PerformanceAnalyticsScreenSelector;

import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  showRecognitionPoints: false,
  showSalaryInfo: false,
  evaluations: [],
  scoreData: [],
  summaryData: [],
  npsName: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_EVALUATIONS:
      return {
        ...state,
        isLoading: true,
        scoreData: [],
        summaryData: [],
      };
    case actionTypes.GET_EVALUATIONS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        evaluations: action.payload.evaluations,
        showRecognitionPoints: action.payload.showRecognitionPoints,
        showSalaryInfo: action.payload.showSalaryInfo,
      };
    case actionTypes.GET_EVALUATIONS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_SCORE_DATA:
      return {
        ...state,
        isLoading: true,
        scoreData: [],
        summaryData: [],
      };
    case actionTypes.GET_SCORE_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        scoreData: action.payload.scoreData,
      };
    case actionTypes.GET_SCORE_DATA_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_SUMMARY_DATA:
      return {
        ...state,
        isLoading: true,
        summaryData: [],
      };
    case actionTypes.GET_SUMMARY_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        summaryData: action.payload.summaryData,
        npsName: action.payload.npsName,
      };
    case actionTypes.GET_SUMMARY_DATA_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_SUMMARY_DATA:
      return { ...state, summaryData: [] };

    default:
      return state;
  }
}

import React, { useRef } from "react";
import ReactApexChart from 'react-apexcharts';
import { Typography } from "@material-ui/core";
import { shadeHexColor } from "@icarius-utils/colors";
import { formatNumberExactDecimals } from "@icarius-utils/format";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { getTheme } from "@icarius-pages/login/selectors";
import useGetRefWidthAndHeight from "@icarius-utils/hooks/useGetRefWidthAndHeight";
import { getChartWidth } from "@icarius-pages/pollReview/components/pollSelection";

const ScoreSelection = (props) => {

  const {
    data,
    scoreSelected,
    handleClick,
  } = props;

  const ref = useRef();
  const { width: containerWidth } = useGetRefWidthAndHeight(ref);
  const color = useSelector(getAppColor);
  const { theme } = useSelector(getTheme);

  const getColorArray = () => {
    const colors = Array(data.scoreTypeList.length).fill(color);
    colors[data.scoreTypeList.findIndex((scoreType) => scoreType === scoreSelected?.name)] = shadeHexColor(color, 0.3);

    return colors;
  }

  const chartData = {
    series: [
      {
        data: data.votesByScore,
      }
    ],
    options: {
      chart: {
        type: 'bar',
        height: 250,
        width: getChartWidth(data.scoreTypeList.length, containerWidth - 20), // el - 20 es por el padding del div
        toolbar: {
          show: false
        },
        events: {
          dataPointSelection(event, chartContext, config) {
            handleClick({ name: data.scoreTypeList[config.dataPointIndex], value: data.votesByScore[config.dataPointIndex] });
          }
        },
      },
      colors: getColorArray(),
      plotOptions: {
        bar: {
          columnWidth: '45%',
          distributed: true,
        }
      },
      xaxis: {
        categories: data.scoreTypeList,
        labels: {
          trim: true,
          style: {
            colors: 'var(--mainText)',
          },
        }
      },
      yaxis: {
        labels: {
          style: {
            colors: 'var(--mainText)',
          },
          formatter: (val) => formatNumberExactDecimals(val, 0),
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      states: {
        active: {
          filter: {
            type: 'none',
          }
        }
      },
      tooltip: {
        marker: {
          show: false,
        },
        followCursor: true,
        theme: theme,
        y: {
          formatter: (val) => formatNumberExactDecimals(val, 0),
          title: {
            formatter: function () {
              return ''
            }
          }
        },
      }
    }
  };

  return (
    <>
      <div className="container relative p-16 sm:p-24 flex flex-row justify-between items-center">
        <div className="flex-col">
          <Typography className="whiteText">
            Cantidad global de evaluaciones por nota
          </Typography>
        </div>
      </div>
      <div ref={ref} style={{ padding: 10, overflow: 'auto' }}>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type={chartData.options.chart.type}
          height={chartData.options.chart.height}
          width={chartData.options.chart.width}
        />
      </div>
    </>
  );
}

export default ScoreSelection;
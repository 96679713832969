import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";

const SelectedScoreData = ({ data }) => {

  useEffect(() => {
    const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
    const element = document.getElementById('selectedScoreData');
    const posi = element.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: posi, behavior: 'smooth' });
  }, [])

  return (
    <div style={{ margin: 20 }} id="selectedScoreData">
      <Typography className="whiteText" align="center" style={{ fontSize: 28 }}>
        {`Grupo de evaluados seleccionados con nota: ${data.name}`}
      </Typography>
      <Typography className="whiteText" align="center" style={{ fontSize: 20 }}>
        {`Cantidad de colaboradores con esta nota: ${data.value}`}
      </Typography>
    </div>
  );
}

export default SelectedScoreData;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsExportingGrid } from "src/app/selectors";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import {
    getPerformanceAnalyticsEvaluationsScoreAction,
    getPerformanceAnalyticsEvaluationsAction,
    getPerformanceAnalyticsEvaluationsSummaryAction,
} from "../actions";
import {
    getIsLoading,
    getEvaluations,
    getScoreData,
    getSummaryData,
    getShowRecognitionPoints,
    getShowSalaryInfo,
    getNpsName,
} from "../selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { RESET_SUMMARY_DATA } from "../actionTypes";

const INITIAL_FILTERS = {
    position: false,
    function: false,
    age: false,
    seniority: false,
    manager: false,
    nationality: false,
    sex: false,
    management: false,
    department: false,
    jobLocation: false,
    costCenter: false,
    workModality: false,
    category: false,
};

const usePerformanceAnalyticsEvaluations = () => {

    const [evaluationSelected, setEvaluationSelected] = useState(null);
    const [scoreSelected, setScoreSelected] = useState(null);
    const [filtersSelected, setFiltersSelected] = useState(INITIAL_FILTERS);

    const dispatch = useDispatch();
    const isExportingGrid = useSelector(getIsExportingGrid);
    const isLoadingAnalytics = useSelector(getIsLoading);
    const showRecognitionPoints = useSelector(getShowRecognitionPoints);
    const showSalaryInfo = useSelector(getShowSalaryInfo);
    const evaluations = useSelector(getEvaluations);
    const scoreData = useSelector(getScoreData);
    const summaryData = useSelector(getSummaryData);
    const npsName = useSelector(getNpsName);

    const societySelected = useSelector(getSocietySelected);

    const years = (evaluations || [])?.map(item => item.year);

    useEffect(() => {
        dispatch(getPerformanceAnalyticsEvaluationsAction());
    }, [dispatch])

    const handleClearEvaluationSelected = () => {
        setEvaluationSelected(null);
    };

    const handleSelectEvaluation = (newEvaluation) => {
        if (evaluationSelected?.code === newEvaluation.code) {
            handleClearEvaluationSelected();
            return;
        }

        setEvaluationSelected(newEvaluation);
        dispatch(getPerformanceAnalyticsEvaluationsScoreAction(newEvaluation.code));
    };

    const handleSelectScore = (newScore) => {
        if (scoreSelected?.name === newScore?.name) {
            setScoreSelected(null);
            return;
        }

        setScoreSelected(newScore);
        dispatch({ type: RESET_SUMMARY_DATA });
    };

    const handleChangeFilter = (value, fieldName) => {
        dispatch({ type: RESET_SUMMARY_DATA });
        setFiltersSelected({
            ...filtersSelected,
            [fieldName]: value,
        });
    }

    const handleGetSummary = () => {
        if (Object.values(filtersSelected).every((item) => !item)) {
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un filtro', severity: 'warning' }));
            return;
        }

        dispatch(getPerformanceAnalyticsEvaluationsSummaryAction({
            evaluationCode: evaluationSelected.code,
            score: scoreSelected.name,
            filters: filtersSelected,
        }));
    }

    const isLoading = (isLoadingAnalytics || isExportingGrid);

    const state = {
        evaluationSelected,
        scoreSelected,
        filtersSelected,
    };

    const handlers = {
        handleClearEvaluationSelected,
        handleSelectEvaluation,
        handleSelectScore,
        handleChangeFilter,
        handleGetSummary,
    };

    return {
        years,
        evaluations,
        scoreData,
        summaryData,
        npsName,
        showRecognitionPoints,
        showSalaryInfo,
        societySelected,
        isLoading,
        state,
        handlers,
    }
}

export default usePerformanceAnalyticsEvaluations;
import paths from "@icarius-localization/paths";
import { periodComparator, periodFilterParams, periodFormatter, periodValueGetter } from "@icarius-table/date";
import { numberComparatorMax2, numberFilterParams, numberFormatterMin0Max0, numberFormatterMin2Max2 } from "@icarius-table/number";

export default [
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Evaluación",
            field: "evaluation",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Año de evaluación",
            field: "year",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Período de la evaluación",
            field: "period",
            filter: "agSetColumnFilter",
            comparator: periodComparator,
            filterParams: periodFilterParams,
            valueFormatter: periodFormatter,
            valueGetter: periodValueGetter,
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Tipo de evaluación",
            field: "type",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Código de empleado",
            field: "employeeCode",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Apellidos y nombres",
            field: "employeeName",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Promedio global desempeño",
            field: "performanceGlobalAverage",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Puntaje final desempeño",
            field: "performanceFinalScore",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Indicador de desempeño",
            field: "indicatorPerformance",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: "IndicatorRenderer",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Nota final desempeño",
            field: "performanceFinalScoreName",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Puntaje máximo posible",
            field: "maximumPossibleScore",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],

        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Ranking",
            field: "ranking",
            filter: "agTextColumnFilter",
            cellRenderer: "ProgressRenderer",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Promedio global potencial",
            field: "potentialGlobalAverage",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Puntaje final potencial",
            field: "potentialFinalScore",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Indicador de potencial",
            field: "indicatorPotential",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin2Max2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: "IndicatorRenderer",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Nota final potencial",
            field: "potentialFinalScoreName",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Cuadrante",
            field: "quadrantClassificationCode",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
            cellRenderer: 'NineBoxImageRenderer'
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Clasificación del cuadrante",
            field: "quadrantName",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Gerencia",
            field: "management",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Departamento",
            field: "department",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Centro de costo de gestión",
            field: "costCenter",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Unidad de negocio",
            field: "division",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Categoría",
            field: "category",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Cargo",
            field: "position",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Función",
            field: "function",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Lugar de trabajo",
            field: "workplace",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Sexo",
            field: "sex",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Edad",
            field: "age",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Grupo etario",
            field: "ageGroup",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.performanceAnalyticsHistoric],
        config: {
            headerName: "Jefe directo",
            field: "boss",
            filter: "agSetColumnFilter",
        }
    },
]
import React from "react";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import NineBoxImageRenderer from "@icarius-table/renderersAndFilters/nineBoxImageRenderer";
import useTemplates from "@icarius-common/templates/components/useTemplates";
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import ProgressRenderer from "@icarius-table/renderersAndFilters/progressRenderer";
import IndicatorRenderer from "@icarius-table/renderersAndFilters/indicatorRenderer";
import { ContainerTitle } from "./container";

const DetailGrid = ({ title, data }) => {

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates('PERFORMANCE_ANALYTICS_HISTORIC');

  const customHeader = (gridRef) => (
    <TemplatesHeader
      gridRef={gridRef}
      user={user}
      templates={templates}
      selectedTemplate={selectedTemplate}
      getRole={getRole}
      handleImportTemplates={handleImportTemplates}
      handleExportTemplates={handleExportTemplates}
      handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
      handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
      handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
      handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
    />
  )

  const child = (gridRef) =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
    </>

  return (
    <div style={{ margin: "15px 0px" }}>
      <ContainerTitle title={title} />
      <CommonPage
        isLoading={isLoadingTemplates}
        gridTitle={title}
        columnDefPage={paths.performanceAnalyticsHistoric}
        customHeader={customHeader}
        rowData={data}
        hasExpand
        hasSelectAll
        hasHelp
        frameworkComponents={{ ProgressRenderer, NineBoxImageRenderer, IndicatorRenderer }}
        ignoreProcessesbyPath
      >
        {child}
      </CommonPage>
    </div>
  )
}

export default DetailGrid;
import React from "react";
import {
  Typography,
  Checkbox,
  Button,
  Grid,
} from "@material-ui/core";

export const FILTERS_TITLES = [
  'Cargo',
  'Función',
  'Grupo etario',
  'Antigüedad',
  'Manager',
  'Nacionalidad',
  'Sexo',
  'Gerencia',
  'Departamento',
  'Lugar de trabajo',
  'Centro de costo de gestión',
  'Modalidad de trabajo',
  'Categoría',
];

const FiltersSelection = (props) => {

  const {
    data,
    handleClick,
    handleSubmit,
  } = props;

  return (
    <div style={{ margin: 20, display: 'flex', flexDirection: 'column' }}>
      <Typography className="whiteText" align="center" style={{ fontSize: 20 }}>
        Filtros
      </Typography>
      <Grid container style={{ padding: '10px 0px' }}>
        {
          Object.keys(data).map((filterName, index) => {
            return (
              <Grid container item xs={12} sm={6} md={4} lg={3} key={index}>
                <div
                  style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }}
                  onClick={() => handleClick(!Boolean(data[filterName]), filterName)}
                >
                  <Checkbox checked={data[filterName]} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {FILTERS_TITLES[index]}
                  </Typography>
                </div>
              </Grid>
            )
          })
        }
      </Grid>
      <Button
        className="whiteText"
        variant="contained"
        onClick={handleSubmit}
        style={{ width: 250, margin: '0 auto' }}
      >
        Buscar
      </Button>
    </div>
  );
}

export default FiltersSelection;
import React from "react";
import { Grid, Typography } from "@material-ui/core";

const AmountList = (props) => {

    const {
        wordsArray,
        countArray,
        colors,
        getWordPercentage,
    } = props;

    return (
        <>
            {
                wordsArray.map((word, index) => {
                    return (
                        <Grid container direction='row' alignItems="center" item key={index}>
                            <Grid container item xs={3}>
                                <Typography className='whiteText' style={{ fontSize: 30, fontWeight: 600 }}>
                                    {countArray[index]}
                                </Typography>
                            </Grid>
                            <Grid container item xs={9} direction='column'>
                                <div style={{ height: 15, width: `${getWordPercentage(index, countArray)}%`, backgroundColor: colors[index] }} />
                                <Typography className='whiteText' style={{ fontSize: 16, textTransform: 'capitalize' }}>
                                    {word}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })
            }
        </>
    );
}

export default AmountList;
import React, { useMemo } from "react";
import ReactWordcloud from 'react-wordcloud';
import { Grid, Typography } from "@material-ui/core";
import DoughnutChart from "./doughnut/doughnutChart";
import SectionContainer from './sectionContainer';
import { FILTERS_TITLES } from "../filtersSelection";

const wordCloudOptions = {
    colors: ["var(--mainText)"],
    enableTooltip: false,
    deterministic: true,
    fontFamily: "roboto",
    fontSizes: [25, 70],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 0,
    scale: "sqrt",
    spiral: "rectangular",
    transitionDuration: 2000,
};

const WordsPollData = ({ data, filters }) => {

    const words = useMemo(() => {
        const createSentence = (value, filterKey, filterName) => {
            if (filterKey === 'sex') return value; // sex no lleva el titulo
            if (filterKey === 'seniority') return `${filterName}: ${value} años`; // seniority se le agrega el años al final
            return `${filterName}: ${value}`;
        }

        const getSentences = () => {
            const rowSentences = [];

            data.forEach((row) => {
                Object.keys(filters).forEach((filter, filterIndex) => {
                    const valueToShow = row[filter];
                    if (!valueToShow) return;

                    rowSentences.push(createSentence(valueToShow, filter, FILTERS_TITLES[filterIndex]));
                });
            });
        
            return rowSentences;
        }

        const getCountArray = () => {
            const countArray = getSentences().reduce((prev, current) => {
                return (prev[current] ? prev[current]++ : prev[current] = 1, prev)
            }, [])
            return countArray;
        }

        const getCloudData = () => {
            const countArray = getCountArray();
            return Object.keys(countArray).map((item, index) => {
                return (
                    {
                        text: item,
                        value: Object.values(countArray)[index],
                    }
                )
            }).sort((a, b) => b.value - a.value);
        }

        return getCloudData();
    }, [data, filters])

    return (
        <div style={{ marginTop: 20 }}>
            <Typography className="whiteText" align="center" style={{ fontSize: 28 }}>
                Nube analítica
            </Typography>
            <SectionContainer ignoreContainer>
                <div style={{ height: 400, minWidth: 400, maxWidth: 800, margin: '0 auto', paddingTop: 20 }}>
                    <ReactWordcloud words={words} options={wordCloudOptions} maxWords={15} />
                </div>
            </SectionContainer>
            <Grid container direction="row" style={{ marginBottom: 10 }}>
                <DoughnutChart words={words} isTopTen={true} />
                <DoughnutChart words={words} />
            </Grid>
        </div>
    );
}

export default WordsPollData;
import * as actionTypes from "./actionTypes";
import {
  getPerformanceAnalyticsHistoricAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";

export const getPerformanceAnalyticsHistoricAction = (includeCurrentYearProcesses, years) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getPerformanceAnalyticsHistoricAPI(`?includeCurrentYearProcesses=${includeCurrentYearProcesses ? "Y" : "N"}&years=${years}`);

    let detailData = response.data && response.data.detailData;
    let averagePerformanceData = response.data && response.data.averagePerformanceData;
    let averagePotentialData = response.data && response.data.averagePotentialData;

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        detailData,
        averagePerformanceData,
        averagePotentialData,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
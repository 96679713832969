import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  averagePerformanceData: [],
  averagePotentialData: [],
  detailData: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return {
        ...state,
        isLoading: true,
        averagePerformanceData: [],
        averagePotentialData: [],
        detailData: [],
      };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        averagePerformanceData: action.payload.averagePerformanceData,
        averagePotentialData: action.payload.averagePotentialData,
        detailData: action.payload.detailData,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

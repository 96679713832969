import React, { useRef } from "react";
import ReactApexChart from 'react-apexcharts';
import { formatNumberExactDecimals } from "@icarius-utils/format";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { Card } from "@material-ui/core";
import useGetRefWidthAndHeight from "@icarius-utils/hooks/useGetRefWidthAndHeight";
import { getChartWidth } from "@icarius-pages/pollReview/components/pollSelection";

const BarChart = (props) => {

  const {
    list,
    data,
  } = props;

  const ref = useRef();
  const { width: containerWidth } = useGetRefWidthAndHeight(ref);
  const color = useSelector(getAppColor);

  const chartData = {
    series: data,
    options: {
      chart: {
        type: 'bar',
        height: 350,
        width: getChartWidth(list.length, containerWidth - 20), // el - 20 es por el padding del div
        toolbar: {
          show: false
        },
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'center',
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value) => formatNumberExactDecimals(value, 2),
        offsetY: -20,
        style: {
          colors: ['var(--mainText)'],
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          }
        },
        active: {
          filter: {
            type: 'none',
          }
        },
      },
      xaxis: {
        categories: list,
        type: 'category',
        labels: {
          trim: true,
          style: {
            colors: 'var(--mainText)',
          },
          formatter: (value) => formatNumberExactDecimals(value, 2),
        }
      },
      yaxis: {
        decimalsInFloat: 1,
        forceNiceScale: true,
        labels: {
          style: {
            colors: 'var(--mainText)',
          },
          formatter: (value) => formatNumberExactDecimals(value, 2),
        }
      },
      legend: {
        labels: {
          colors: 'var(--mainText)',
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
      },
      colors: [color],
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <Card style={{ width: '100%', padding: 20 }}>
      <div ref={ref} style={{ overflow: 'auto', padding: 10 }}>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type={chartData.options.chart.type}
          height={chartData.options.chart.height}
          width={chartData.options.chart.width}
        />
      </div>
    </Card>
  )
}

export default BarChart;
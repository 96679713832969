import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";

const HeaderDataDisplay = ({ name, value }) => {
  return (
    <Grid container item direction="column" xs={12} sm={6} md={3}>
      <Typography className="whiteText">
        {name}:
      </Typography>
      <Typography className="whiteText" style={{ fontWeight: 600 }}>
        {value}
      </Typography>
    </Grid>
  )
}

const SelectedEvaluationData = ({ data }) => {

  useEffect(() => {
    const yOffset = -document.getElementsByClassName('main-header')[0].offsetHeight;
    const element = document.getElementById('selectedEvaluationData');
    const posi = element.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: posi, behavior: 'smooth' });
  }, [])

  return (
    <div id="selectedEvaluationData">
      <div style={{ margin: 20 }}>
        <Typography className="whiteText" align="center" style={{ fontSize: 28 }}>
          {data.name}
        </Typography>
        <Typography className="whiteText" align="center" style={{ fontSize: 20 }}>
          {data.type === 'P' ? 'Desempeño y Potencial' : 'Desempeño'}
        </Typography>
      </div>
      <Grid container item xs={12} spacing={2} style={{ margin: 20 }}>
        <HeaderDataDisplay name={'Estado'} value={data.status} />
        <HeaderDataDisplay name={'Período de evaluación'} value={`Del ${data.periodFromDate} al ${data.periodEndDate}`} />
        <HeaderDataDisplay name={'Período para evaluación de objetivos'} value={data.periodName} />
        <HeaderDataDisplay name={'Cantidad de evaluados'} value={data.answersAmount} />
      </Grid>
    </div>
  );
}

export default SelectedEvaluationData;
import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIsExportingGrid } from "src/app/selectors";
import { getSocietySelected } from "@icarius-pages/login/selectors";
import {
    getPerformanceAnalyticsTalentQuadrantAction,
    getPerformanceAnalyticsTalentAction,
    getPerformanceAnalyticsTalentSummaryAction,
} from "../actions";
import {
    getIsLoading,
    getEvaluations,
    getQuadrantData,
    getSummaryData,
    getShowRecognitionPoints,
    getShowSalaryInfo,
    getNpsName,
} from "../selectors";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { RESET_SUMMARY_DATA } from "../actionTypes";

const INITIAL_FILTERS = {
    position: false,
    function: false,
    age: false,
    seniority: false,
    manager: false,
    nationality: false,
    sex: false,
    management: false,
    department: false,
    jobLocation: false,
    costCenter: false,
    workModality: false,
    category: false,
};

const usePerformanceAnalyticsTalent = () => {

    const [evaluationSelected, setEvaluationSelected] = useState(null);
    const [quadrantSelected, setQuadrantSelected] = useState(null);
    const [characteristicsDialogIsOpen, setCharacteristicsDialogIsOpen] = useState(false);
    const [filtersSelected, setFiltersSelected] = useState(INITIAL_FILTERS);

    const dispatch = useDispatch();
    const isExportingGrid = useSelector(getIsExportingGrid);
    const isLoadingAnalytics = useSelector(getIsLoading);
    const showRecognitionPoints = useSelector(getShowRecognitionPoints);
    const showSalaryInfo = useSelector(getShowSalaryInfo);
    const evaluations = useSelector(getEvaluations);
    const quadrantData = useSelector(getQuadrantData);
    const summaryData = useSelector(getSummaryData);
    const npsName = useSelector(getNpsName);

    const societySelected = useSelector(getSocietySelected);

    const years = (evaluations || [])?.map(item => item.year);

    useEffect(() => {
        dispatch(getPerformanceAnalyticsTalentAction());
    }, [dispatch])

    const handleClearEvaluationSelected = () => {
        setEvaluationSelected(null);
        setQuadrantSelected(null);
        setFiltersSelected(INITIAL_FILTERS);
    };

    const handleSelectEvaluation = (newEvaluation) => {
        if (evaluationSelected?.code === newEvaluation.code) {
            handleClearEvaluationSelected();
            return;
        }

        setEvaluationSelected(newEvaluation);

        dispatch(getPerformanceAnalyticsTalentQuadrantAction(newEvaluation.code));
    };

    const handleSelectQuadrant = (newQuadrant) => {
        if (quadrantSelected?.key === newQuadrant?.key) {
            setQuadrantSelected(null);
            return;
        }
        
        const collaboratorsAmount = quadrantData?.collaborators?.filter((item) => item.quadrantClassification === newQuadrant.key).length;

        if (!collaboratorsAmount) {
            dispatch(openSnackbarAction({ msg: 'No hay colaboradores en el cuadrante seleccionado', severity: 'warning' }));
            return;
        }

        setQuadrantSelected(newQuadrant);
        dispatch({ type: RESET_SUMMARY_DATA });
    };

    const handleChangeFilter = (value, fieldName) => {
        dispatch({ type: RESET_SUMMARY_DATA });
        setFiltersSelected({
            ...filtersSelected,
            [fieldName]: value,
        });
    }

    const handleGetSummary = () => {
        if (Object.values(filtersSelected).every((item) => !item)) {
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un filtro', severity: 'warning' }));
            return;
        }

        dispatch(getPerformanceAnalyticsTalentSummaryAction({
            evaluationCode: evaluationSelected.code,
            quadrant: quadrantSelected.key,
            filters: filtersSelected,
        }));
    }

    const handleOpenCharacteristicsDialog = () => {
        setCharacteristicsDialogIsOpen(true);
    }

    const handleCloseCharacteristicsDialog = () => {
        setCharacteristicsDialogIsOpen(false);
    }

    const nineBoxCollaborators = useMemo(() => {
        return (quadrantData?.collaborators || []).map((item) => {
            return {
                ...item,
                position: {
                    x: parseInt(item.performance),
                    y: parseInt(item.potential),
                }
            };
        });
    }, [quadrantData.collaborators])

    const isLoading = (isLoadingAnalytics || isExportingGrid);

    const state = {
        evaluationSelected,
        quadrantSelected,
        filtersSelected,
        characteristicsDialogIsOpen,
    };

    const handlers = {
        handleClearEvaluationSelected,
        handleSelectEvaluation,
        handleSelectQuadrant,
        handleChangeFilter,
        handleGetSummary,
        handleOpenCharacteristicsDialog,
        handleCloseCharacteristicsDialog,
    };

    return {
        years,
        evaluations,
        quadrantData,
        nineBoxCollaborators,
        summaryData,
        npsName,
        showRecognitionPoints,
        showSalaryInfo,
        societySelected,
        isLoading,
        state,
        handlers,
    }
}

export default usePerformanceAnalyticsTalent;
import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CommonPage from "@icarius-common/commonPage";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import Summary from "@icarius-pages/performanceAnalyticsEvaluations/components/summary";
import EvaluationSelection from "@icarius-pages/performanceAnalyticsEvaluations/components/evaluationSelection";
import SelectedEvaluationData from "@icarius-pages/performanceAnalyticsEvaluations/components/selectedEvaluationData";
import FiltersSelection from "@icarius-pages/performanceAnalyticsEvaluations/components/filtersSelection";
import NineBoxChart from "@icarius-common/nineBoxChart";
import QuadrantTooltipData from "@icarius-pages/nineBoxClassification/components/chartPage/quadrantTooltipData";
import Collaborator from "@icarius-pages/nineBoxClassification/components/chartPage/collaborator";
import usePerformanceAnalyticsTalent from "./usePerformanceAnalyticsTalent";
import SelectedQuadrantData from "./selectedQuadrantData";
import CharacteristicsDialog from "./characteristicsDialog";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const PerformanceAnalyticsTalent = () => {

  const {
    isLoading,
    years,
    evaluations,
    quadrantData,
    nineBoxCollaborators,
    summaryData,
    npsName,
    showRecognitionPoints,
    showSalaryInfo,
    societySelected,
    state,
    handlers,
  } = usePerformanceAnalyticsTalent();

  const history = useHistory();
  const color = useSelector(getAppColor);

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString('goBack')}
      onClick={() => history.push(paths.performanceAnalytics)}
      type={'goBack'}
    />
  )

  const renderQuadrantTooltipContent = (data, piecesOnSquareAmount) => {
    if (piecesOnSquareAmount || !data) return '';
    return <QuadrantTooltipData data={data} />
  };

  return (
    <CommonPage
      isLoading={isLoading}
      title={'Analítica del talento'}
      menuItems={[goBackButton]}
      isNotGridPage
    >
      {
        !isLoading && !Boolean(evaluations?.length) &&
        <Grid container alignItems="center" justify="center" direction="column" style={{ marginTop: 150 }}>
          <Typography color="textSecondary" className="whiteText" variant="h2" style={{ fontSize: 32, fontWeight: 700, marginBottom: 16 }}>
            {"Aún no se han registrado evaluaciones."}
          </Typography>
        </Grid>
      }
      {
        Boolean(evaluations.length) &&
        <>
          <Grid container item xs={12} className="p-0 principal chart-wrapper" id="main-content" style={{ overflow: 'initial' }}>
            <EvaluationSelection
              years={years}
              data={evaluations}
              evaluationSelected={state.evaluationSelected}
              handleClick={handlers.handleSelectEvaluation}
              handleYearClick={handlers.handleClearEvaluationSelected}
            />
          </Grid>
          {
            Boolean(state.evaluationSelected) && Boolean(Object.keys(quadrantData).length) &&
            <>
              <SelectedEvaluationData
                data={state.evaluationSelected}
              />
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  style={{ color: "white", background: color }}
                  onClick={handlers.handleOpenCharacteristicsDialog}
                >
                  Características de los cuadrantes
                </Button>
              </div>
              <NineBoxChart
                initialData={nineBoxCollaborators}
                nineBoxDefinition={quadrantData.nineBoxDefinition}
                onQuadrantClick={handlers.handleSelectQuadrant}
                renderQuadrantTooltipContent={renderQuadrantTooltipContent}
                renderPieceContent={(props) => <Collaborator {...props} />}
                hideFreeUsers
              />
              {
                Boolean(state.quadrantSelected) &&
                <>
                  <SelectedQuadrantData
                    data={state.quadrantSelected}
                    collaborators={nineBoxCollaborators}
                  />
                  <Grid container item xs={12} className="p-0 principal chart-wrapper" id="main-content">
                    <FiltersSelection
                      data={state.filtersSelected}
                      handleClick={handlers.handleChangeFilter}
                      handleSubmit={handlers.handleGetSummary}
                    />
                  </Grid>
                </>
              }
            </>
          }
          {
            Boolean(summaryData.length) &&
            Boolean(state.quadrantSelected) &&
            Boolean(state.evaluationSelected) &&
            Object.values(state.filtersSelected).some((item) => item) &&
            <Summary
              data={summaryData}
              npsName={npsName}
              filters={state.filtersSelected}
              showRecognitionPoints={showRecognitionPoints}
              showSalaryInfo={showSalaryInfo}
              societySelected={societySelected}
            />
          }
          {
            state.characteristicsDialogIsOpen &&
            <CharacteristicsDialog
              open={state.characteristicsDialogIsOpen}
              data={quadrantData.nineBoxDefinition}
              handleClose={handlers.handleCloseCharacteristicsDialog}
            />
          }
        </>
      }
    </CommonPage>
  );
}

export default PerformanceAnalyticsTalent;

import NAME from "./constants";

export const GET_EVALUATIONS = NAME + "/GET_EVALUATIONS";
export const GET_EVALUATIONS_FULFILLED = NAME + "/GET_EVALUATIONS_FULFILLED";
export const GET_EVALUATIONS_REJECTED = NAME + "/GET_EVALUATIONS_REJECTED";

export const GET_QUADRANT_DATA = NAME + "/GET_QUADRANT_DATA";
export const GET_QUADRANT_DATA_FULFILLED = NAME + "/GET_QUADRANT_DATA_FULFILLED";
export const GET_QUADRANT_DATA_REJECTED = NAME + "/GET_QUADRANT_DATA_REJECTED";

export const GET_SUMMARY_DATA = NAME + "/GET_SUMMARY_DATA";
export const GET_SUMMARY_DATA_FULFILLED = NAME + "/GET_SUMMARY_DATA_FULFILLED";
export const GET_SUMMARY_DATA_REJECTED = NAME + "/GET_SUMMARY_DATA_REJECTED";
export const RESET_SUMMARY_DATA = NAME + "/RESET_SUMMARY_DATA";
import React from "react";
import { Typography } from "@material-ui/core";

const PercentageList = (props) => {

    const {
        wordsArray,
        countArray,
        colors,
        getWordPercentage,
    } = props;

    return (
        <>
            {
                wordsArray.map((word, index) => {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: 10, margin: '5px 0px' }}>
                            <div style={{ flexShrink: 0, height: 25, width: 25, backgroundColor: colors[index] }} />
                            <Typography className='whiteText'>
                                <span style={{ fontSize: 20, fontWeight: 600 }}>
                                    {`${getWordPercentage(index, countArray)}%\t`}
                                </span>
                                <span style={{ fontSize: 20, textTransform: 'capitalize' }}>
                                    {word}
                                </span>
                            </Typography>
                        </div>
                    )
                })
            }
        </>
    );
}

export default PercentageList;